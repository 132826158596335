import React from "react"
import { Layout, FlexRow, Parallax } from "@components/layout"
import { ProductItem } from "@components/ecommerce"

import { cart__wrapp } from "./styles/simpleCart.module.scss"

const renderWithAllProps = (Comp) => (entry, key) => {
  return <Comp {...entry} key={key} />
}

const SingleCategory = ({ pageContext }) => {
  const { products, posts, page, categories } = pageContext
  console.log('prrr', products)
  const { translations } = page
  const translationUrls = {
    pl:`/pl/shop/categories/${translations[0].slug}/`,
    en:`/en/shop/categories/${translations[0].slug}/`
  }
  return (
    <Layout {...page} translationUrl={translationUrls}>
      <Parallax height={400} image={page.featuredImage} >
        <h1>{page.name}</h1>
      </Parallax>
      <div className={cart__wrapp}>
        {products.map(renderWithAllProps(ProductItem))}
      </div>
    </Layout>
  )
}

export default SingleCategory
